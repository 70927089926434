import react from 'react'
import {useEffect, useState} from 'react'

import {TopBar} from "./topbar.js"

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Icon from '@material-ui/core/Icon';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import TextField from '@mui/material/TextField'

import ReactJson from 'react-json-view'

import { getStorage, ref, uploadBytes } from "firebase/storage";

import { collection, getDoc, getDocs, addDoc, doc, onSnapshot } from "firebase/firestore";


// var editorValue = new EditorValue();
// console.log("editorValue", editorValue.isState())

const ServiceList = (slist) => {
    return (
         <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
 {/*}                <TableHead>
                 <TableRow>
                    <TableCell >Name</TableCell>
                    <TableCell >Input</TableCell>                    
                    <TableCell >Output</TableCell>
                  </TableRow>
                </TableHead> */}
                <TableBody>
                  {slist.map((row) => (
                    <TableRow
                      key={row.display}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell width="50%">{row.display}</TableCell>
                      <TableCell width="25%">{Array.isArray(row.inputs) 
                          ? row.inputs.map((ins)=><><Box sx={{my:-1,p:-3}}>{ins}</Box><br/></>)
                          : row.inputs}
                      </TableCell>
                      <TableCell >{Array.isArray(row.outputs)? row.outputs.join(",") : row.outputs}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
        </TableContainer>        
    )
}


export const LandingPage = (props) => {

    const [services,setServices] = useState(null)

    useEffect(()=>{
        const tmp = []
        getDocs(collection(props.state.getFire().db,"providers"))
        .then((providers)=>{
            Object.entries(providers.docs).forEach(([k,prov])=>{
                tmp.push(prov.data())
            })
        })
        .then(()=>{console.log("HERE",tmp); setServices({providers:tmp})})
    },[])

 //   console.log("Rendering with ",props.state.getFire())

    return (
        <>
        {services === null && <Box sx={{borderWidth:5, textAlign:"center", width:"100%"}}>
                                <p>Marketplace coming soon.</p>
                             </Box>
        }
        {services != null && 
         <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell >Provider</TableCell>
                    <TableCell >Services (Description, Required Inputs, Produces...)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {services.providers.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell >{row.name}</TableCell>
                      <TableCell >{ServiceList(row.provides)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
        </TableContainer>
        }
        </>
    )
}

