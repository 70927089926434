import React from 'react'
import {useState, useEffect} from 'react'

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import Icon from '@material-ui/core/Icon';
// import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';


export const TopBar = (props) => {

	const [pName,setPName] = useState("API Key")

	useEffect(()=>{
		props.state.registerForProjChange((s)=>{
			if (s) setPName(s.data().Name) 
			else setPName("API Key")
		})
	},[])

	const gotoState = (n) => {
		props.state.clrProject()
		props.state.changeState(n)
	}

	return (
       <Box sx={{ flexGrow: 1 }}>
	      <AppBar position="static">  {/* sx={{height:'30px'}} */}
	        <Toolbar>
	{/*           <IconButton
	            size="small"
	            edge="start"
	            color="inherit"
	            aria-label="menu"
	            sx={{ mr: 2 }}
	          >
	             <MenuIcon onClick={()=>console.log("hamburger")}/> 
	          </IconButton>
	          <Menu>
		           <MenuItem onClick={projects}>
			          	<Typography variant="h6" component="div" sx={{ flexGrow: 1 }} >
			           	 Projects
			          	</Typography>
		          </MenuItem>
	          </Menu>*/}
	        <Box sx={{flexGrow: 1}} >
	        	<Button color="inherit" onClick={()=>gotoState("Land")}>
	        		<Typography variant="h6">{'\u2302'}</Typography>
	        	</Button>
	        	<Button color="inherit" onClick={()=>gotoState("Projects")}>
		        	<Typography id="topBarProject">
		        	  {pName}{'\u21E9'}
		        	</Typography>
		        	{/*<Icon>ArrowCircleDown</Icon>*/}
	        	</Button>
	        </Box>
	          {props.state.getComponent("SignInOut")}
	        </Toolbar>
	      </AppBar>
	    </Box>
      )
}